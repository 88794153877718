// import 'bootstrap/dist/css/bootstrap.min.css';

$(function(){

    // alert('hello');
    // hover over 

    $('.headlineWithIcons-icons p').hover(
        function() {
            // $('.headlineWithIcons-icons p').
            //$(this).parent().find('.icon-overlay').addClass('jos');
            //$(this).parent().find('.icon-overlay img').css('opacity','.15');
          // $( this ).append( $( "<span> ***</span>" ) );
        }, function() {
            //$(this).parent().find('.icon-overlay').removeClass('jos');
          // $( this ).find( "span" ).last().remove();
        }
    );

    /* $( ".headlineWithIcons-icons p" ).hover(function() {
        $(this).parent().find('.icon-overlay').addClass('jos');
        // $(this).parent().find('.icon-overlay').css('opacity', 1);
        // $( this ).fadeOut( 100 );
        // $( this ).fadeIn( 500 );
        // console.log('hovered over');
    });  */   


})

